import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Wrapper from "../components/wrapper"
import SectionTitle from "../components/sectionTitle"
import text from "../constants/labels.json"
import PlaceCard from "../components/placeCard"
import teatroAntofagasta from "../images/teatroAntof.jpg"
import museoRuinas from "../images/museoRuinas.jpeg"
import artequin from "../images/artequin.jpeg"

const Artes = () => (
  <Layout>
    <Seo title="Artes" />
    <Background>
      <Wrapper>
        <Container>
          <SectionTitle title={text.labels.pages.arts} textColor="#26272d" />
          <PlaceTitle>Estos son los lugares a visitar:</PlaceTitle>
          <SectionContainer>
            <InfoSection>
              <PlaceCard
                picSrc={teatroAntofagasta}
                cardColor="#D964A6"
                title={text.labels.info.places.arts.places.teatro.title}
                placeInfo={
                  text.labels.info.places.arts.places.teatro.description
                }
                placeLink={text.labels.info.places.arts.places.teatro.link}
                buttonTextColor="#f5f4f4"
                buttonBgColor="#5a71eb"
                buttonHoverColor="#677ef5"
                buttonActiveColor="#4b61d6"
                isRight={false}
              />
              <PlaceCard
                picSrc={museoRuinas}
                cardColor="#D964A6"
                title={text.labels.info.places.arts.places.museoRuinas.title}
                placeInfo={
                  text.labels.info.places.arts.places.museoRuinas.description
                }
                placeLink={text.labels.info.places.arts.places.museoRuinas.link}
                buttonTextColor="#f5f4f4"
                buttonBgColor="#5a71eb"
                buttonHoverColor="#677ef5"
                buttonActiveColor="#4b61d6"
                isRight={true}
              />
              <PlaceCard
                picSrc={artequin}
                cardColor="#D964A6"
                title={text.labels.info.places.arts.places.artequin.title}
                placeInfo={
                  text.labels.info.places.arts.places.artequin.description
                }
                placeLink={text.labels.info.places.arts.places.artequin.link}
                buttonTextColor="#f5f4f4"
                buttonBgColor="#5a71eb"
                buttonHoverColor="#677ef5"
                buttonActiveColor="#4b61d6"
                isRight={false}
              />
              <StyledLink to="/">Volver a la página principal</StyledLink>
            </InfoSection>
          </SectionContainer>
        </Container>
      </Wrapper>
    </Background>
  </Layout>
)

const Container = styled.div`
  margin-top: 200px;
  @media screen and (max-width: 770px) {
    margin-top: 50px;
  }
`
const PlaceTitle = styled.h3`
  margin-top: 45px;
  text-align: center;
`
const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 650px;
  font-size: 1.15rem;
  line-height: 2rem;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`
const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`
const StyledLink = styled(Link)`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  text-decoration: none;
`
const Background = styled.div`
  background-color: #ffc9e6;
  padding-top: 50px;
`

export default Artes
